/*

All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 320).

The 'supports' rule will only run if your browser supports CSS grid.

Flexbox is used as a fallback so that browsers which don't support grid will still recieve an identical layout.

*/

$sideBar: #fcfeff;
$sideBarColor: #525252;
$navBar: #07588a;
$bodyColor: #e2e4e6;
$white: #fff;
$black: #000;
$listTitleColor: #525252;
$modalColor: #fefefe;

/* Base styles */

:root {
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  background-color: $bodyColor;
}

.flexContainer {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 2px;
  height: 78px;
}

.flexSpaceAround {
  justify-content: space-evenly;
}

.flexItem {
  flex: 1;
}
.flexStart {
  justify-content: flex-start;
}
.flexEnd {
  justify-content: flex-end;
}
.blueBackground {
  background: rgb(7, 88, 138);
}
.nav {
  list-style: none;
}
.nav a:not(.user-profile), span {
  text-decoration: none;
  display: block;
  padding: 0 1em;
  color: $white;
}

.nav button {
  text-decoration: none;
  display: block;
  padding: 0 1em;
  color: $white;
  background:none!important;
  border:none;
  font: inherit;
  cursor: pointer;

  &.sidebar-menu-btn {
    padding: 0;
  }
}

.add-border-black {
  border: 0.5px solid $black;
}

.logo-title {
  width: 100px;
  color: $white
}

.lists-container {
  display: flex;
  align-items: flex-start;
  padding: 0 0.8rem 0.8rem;
  overflow-x: auto;
  height: calc(100vh - 11rem);
  margin-top: 0.8rem;

  &.accomodate-load-more {
    height: calc(100vh - 12rem);
  }
}

.list {
  flex: 0 0 27rem;
  display: flex;
  flex-direction: column;
  background-color: $bodyColor;
  max-height: calc(100vh - 11.8rem);
  border-radius: 0.3rem;
  margin-right: 1rem;
  height: 100%;
}

.list:last-of-type {
  margin-right: 0;
}

.list-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: $listTitleColor;
  padding: 1rem;
  text-align: center;
}

.list-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 0 0.6rem 0.5rem;
  overflow-y: auto;
}

.list-items li {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3;
  background-color: $white;
  color: #4d4d4d;
  border-bottom: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
  word-wrap: break-word;
}

.list-items li:last-of-type {
  margin-bottom: 0;
}

.refresh-container {
  background: $navBar;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  line-height: 25px;
}

.refresh-button {
  font-size: 18px;
  background: $navBar;
  color: #fff;
  border: none;
  display: flex;
  cursor: click;
}

.refresh-button:hover {
  opacity: 0.75;
}

.user-profile-container {
  padding-right: 14px;

  button {
    padding: 0;
  }

  button:hover {
    opacity: 0.75;
  }
}

.user-profile {
  display: flex;
}

.user-avatar {
  height: 30px;
  border-radius: 50%;
}

.assignee-avatar {
  height: 20px;
  margin: 2px;
  border-radius: 10%;
}

.assignee-avatar-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 4px;
  padding: 0;
}

.mock-card {
  width: 260px;
  height: 100px;
}

.empty-board {
  width: 300px;
  height: 250px;
  background: $white;
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $navBar;
  border-radius: 1%;
}

.card {
  margin-bottom: 5px;
}

/* Add some padding inside the card container */
.card-container {
  padding: 2px 16px;

  p {
    word-break: break-word;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
}

.card-header {
  display: flex;
}

.issue-number-container {
  display: flex;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.center {
  justify-content: center;
}

.issue-number {
  text-decoration: none;
  color: #2278cf;
  padding: 1px;
}

.issue-pull-request-icon {
  width: 10px;
  height: 13px;
  margin-top: 4px;
  margin-right: 2px;
  opacity: 0.5;
}

.pull-request {
  border: 1px solid #32CD32;
}

.issue-number:hover {
  color: #428bca;
}

.loader-container {
  text-align: center;
  width: 100%;
}

.loading-more {
  font-size: 14px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.labels-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.github-label {
  padding: 5px;
  margin: 2px;
  font-size: 10px;
  border-radius: 0.3rem;

  &.light {
    color: $black;
  }

  &.dark {
    color: $white;
    font-weight: 700;
  }
}

.member-dropdown {
  margin-right: 1em;
}

.member-select-label {
  color: $white;
  font-weight: 800;
  display: block;
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  font-size: 14px;
}

.select-css {
	display: block;
	font-size: 12px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .3em 1.4em .3em .8em;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: $white;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling.

*/

@supports (display: grid) {
  body {
      display: grid;
      grid-template-rows: 4rem 3rem auto;
      grid-row-gap: 0.8rem;
  }

  .masthead {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 2rem;
  }

  .boards-menu {
      display: grid;
      grid-template-columns: 9rem 18rem;
      grid-column-gap: 0.8rem;
  }

  .user-settings {
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-column-gap: 0.8rem;
  }

  .board-controls {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 1rem;
  }

  .logo-menu-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    padding-left: 1.4rem;
  }

  .lists-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .list {
      display: grid;
      grid-template-rows: auto minmax(auto, 1fr) auto;
  }

  .list-items {
      display: grid;
      grid-row-gap: 0.6rem;
  }

  .logo,
  .list,
  .list-items,
  .boards-btn,
  .board-controls .btn,
  .user-settings-btn {
      margin: 0;
  }

  .column-controls-container {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-gap: 25px;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: $sideBar;
    overflow-x: hidden;
    transition: 0.5s ease;
    padding-top: 10px;
    opacity: 0;

    .controls-menu-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 25px;
      text-decoration: none;
      background-color: $sideBar;
      border: none;
      color: $sideBarColor;
      font-weight: bold;
    }
  }

  .show-side-menu {
    width: 250px;
    opacity: 1;
  }

  .column-controls {
    align-content: start;
    background: $sideBar;
    display: grid;
    font-size: 14px;
    height: auto;
    justify-content: start;
    padding: 5px;
    width: 100%;
    grid-template-rows: auto auto auto auto auto;
    justify-items: start;
    grid-gap: 5px;
    padding-left: 25px;
    color: $sideBarColor;

    &.checkbox {
      grid-template-columns: auto auto;

      span {
        color: $sideBarColor;
        font-weight: bold;
        padding: 0;
      }
    }
  }

  .column-controls-button {
    background: $sideBarColor;
    border: 1px solid $sideBar;
    border-top-left-radius: 75%;
    border-top-right-radius: 75%;
  }

  .logout-button-container {
    align-self: end;
  }

  .logout-button {
    width: 100%;
    height: 3rem;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: $sideBar;
    background: $navBar;
    cursor: pointer;
  }

  .logout-button:hover {
    opacity: 0.9;
  }

  .js-hide {
    display: none;
  }

  .sidebar-menu-btn {
    padding: 0;
  }

  .sidebar-menu-content {
    line-height: 0;
    padding: 0;
  }
}

.modal {
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;

}

.modal-card {
  display: flex;
  background: $navBar;
  color: $white;
  font-size: 18px;
  font-weight: bold;
  height: 100px;
  width: 250px;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: $white;
  }
}
